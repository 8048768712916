<div class="trading-headers">
  <div class="flex-border">
    <div class="blue-line-toggle">
      <div class="target-toggle-button">
        <div class="toggle-button-red">
          <mat-button-toggle-group
            *ngIf="tradeReportTradingStrategyId"
            hideSingleSelectionIndicator="true"
            name="fontStyle"
            #group="matButtonToggleGroup"
          >
            <mat-button-toggle
              [checked]="tradeReportTradingStrategyId === firstTradingStrategy.id"
              (click)="changeTradeReportTradingStrategy(firstTradingStrategy.id)"
            >
              {{ firstTradingStrategy.name }}
            </mat-button-toggle>
            <mat-button-toggle
              [checked]="tradeReportTradingStrategyId === secondTradingStrategy.id"
              (click)="changeTradeReportTradingStrategy(secondTradingStrategy.id)"
            >
              {{ secondTradingStrategy.name }}
            </mat-button-toggle>
            <mat-button-toggle
              [checked]="tradeReportTradingStrategyId === compareProfit.id"
              (click)="changeTradeReportTradingStrategy(compareProfit.id)"
            >
              Compare Profit
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
    <div class="right-view-toggle">
      <p class="cap">Results based on $10,000 account using 2% risk ($200)</p>
    </div>
  </div>
</div>

<div
  (scroll)="scrollHandler()"
  class="report-con-tbl simpleViewTbl"
  #reportHtml
  id="tradReportHtml"
>
  <table *ngIf="tradeReportTradingStrategyId !== compareProfit.id">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th>
          <div class="start-end-date-name">
            <p>Entry Date</p>
            <p>Exit Date</p>
          </div>
        </th>
        <th class="text-center">Size</th>
        <th class="text-center">Position</th>
        <th class="text-right">Entry Price</th>
        <th class="text-right">Stop Loss</th>
        <th class="text-right">Profit Target</th>
        <th class="text-right">Exit Price</th>
        <th>
          <div class="profit-center">Profit</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let trade of tableData; let i = index">
        <td class="text-center">{{ i + 1 }}</td>
        <td>
          <div class="start-end-date-value">
            <p>{{ trade.display_entry_date }}</p>
            <div
              *ngIf="trade.exit_date"
              class="date-progress-bar"
            >
              <span>{{ trade.display_duration }}d</span>
              <div
                [style.width.%]="trade.display_duration_progress"
                class="progress-line"
              ></div>
            </div>
            <p *ngIf="trade.exit_date">{{ trade.display_exit_date }}</p>
          </div>
        </td>
        <td class="text-right">{{ trade.display_shares_count }}</td>
        <td class="text-center">
          <div [ngClass]="trade.display_position_class">
            {{ trade.display_position }}
          </div>
        </td>
        <td class="fonts-14px text-right">{{ trade.display_entry_price }}</td>
        <td class="text-right">{{ trade.display_stop_loss }}</td>
        <td class="text-right">{{ trade.display_target_profit }}</td>
        <td class="text-right">
          <div
            *ngIf="!isNullOrUndefined(trade.exit_price)"
            class="d-space-between"
          >
            <span>{{ trade.display_exit_price }}</span>
          </div>
        </td>
        <td *ngIf="trade.total_profit != null">
          <div
            [class]="trade.display_total_profit_class"
            class="profit-bar"
          >
            <p>{{ trade.display_total_profit }}</p>
            <div class="progress-space">
              <div class="profit-progress">
                <div
                  [style.width.%]="trade.display_total_profit_progress"
                  class="profit-line"
                ></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <app-compare-profits
    [firstTradingStrategy]="firstTradingStrategy"
    [secondTradingStrategy]="secondTradingStrategy"
    [firstStrategyTrades]="firstStrategyTrades"
    [secondStrategyTrades]="secondStrategyTrades"
    [exchange]="exchange"
    *ngIf="tradeReportTradingStrategyId === compareProfit.id"
  >
  </app-compare-profits>
</div>
