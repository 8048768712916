<div class="trading-headers scroll-style">
  <div class="flex-border">
    <div class="blue-line-toggle">
      <div class="target-toggle-button">
        <div class="toggle-button-red">
          <mat-button-toggle-group
            *ngIf="tradeReportTradingStrategyId"
            hideSingleSelectionIndicator="true"
            name="fontStyle"
            #group="matButtonToggleGroup"
          >
            <mat-button-toggle
              [checked]="tradeReportTradingStrategyId === firstTradingStrategy.id"
              (click)="changeTradeReportTradingStrategy(firstTradingStrategy.id)"
            >
              {{ firstTradingStrategy.name }}
            </mat-button-toggle>
            <mat-button-toggle
              [checked]="tradeReportTradingStrategyId === secondTradingStrategy.id"
              (click)="changeTradeReportTradingStrategy(secondTradingStrategy.id)"
            >
              {{ secondTradingStrategy.name }}
            </mat-button-toggle>
            <mat-button-toggle
              [checked]="tradeReportTradingStrategyId === compareProfit.id"
              (click)="changeTradeReportTradingStrategy(compareProfit.id)"
            >
              Compare Profit
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
    <div class="right-view-toggle">
      <p class="cap">Results based on $10,000 account using 2% risk ($200)</p>
    </div>
  </div>
</div>

<div
  (scroll)="scrollHandler()"
  class="report-con-tbl simpleViewTbl"
  #reportHtml
  id="tradReportHtml"
>
  <table *ngIf="tradeReportTradingStrategyId !== compareProfit.id">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th>
          <div class="start-end-date-name">
            <p>Entry Date</p>
            <p>Exit Date</p>
          </div>
        </th>
        <th class="text-center">
          <p>Size</p>
          <p>Position</p>
        </th>
        <th class="text-right">
          <p>Entry <br />Price</p>
        </th>
        <th class="text-right">
          <p>Stop Loss</p>
          <p>Profit Target</p>
        </th>
        <th class="text-right">
          <p>Exit <br />Price</p>
        </th>
        <th>
          <div class="profit-center">Profit</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let trade of tableData; let i = index">
        <td class="text-center">{{ i + 1 }}</td>
        <td>
          <div class="start-end-date-value">
            <p>{{ trade.display_entry_date }}</p>
            <p *ngIf="trade.exit_date">{{ trade.display_exit_date }}</p>
          </div>
        </td>
        <td class="text-right">
          <p>{{ trade.display_shares_count }}</p>
          <div [ngClass]="trade.display_position_class">
            {{ trade.display_position }}
          </div>
        </td>
        <td class="fonts-14px text-right">{{ trade.display_entry_price }}</td>
        <td class="text-right">
          <p class="redText">{{ trade.display_stop_loss }}</p>
          <p class="greenText">{{ trade.display_target_profit }}</p>
        </td>
        <td class="text-right">
          <div class="fonts-14px">
            <p *ngIf="!isNullOrUndefined(trade.exit_price)">{{ trade.display_exit_price }}</p>
          </div>
        </td>
        <td *ngIf="trade.total_profit != null">
          <div class="progress-bar-parent w-48">
            <div
              [class]="trade.display_total_profit_class"
              [style.width.%]="trade.display_total_profit_progress"
              class="progress-bar"
            >
              <div class="progress-inner-value">{{ trade.display_total_profit }}</div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <app-compare-profits
    [firstTradingStrategy]="firstTradingStrategy"
    [secondTradingStrategy]="secondTradingStrategy"
    [firstStrategyTrades]="firstStrategyTrades"
    [secondStrategyTrades]="secondStrategyTrades"
    [exchange]="exchange"
    *ngIf="tradeReportTradingStrategyId === compareProfit.id"
  >
  </app-compare-profits>
</div>
